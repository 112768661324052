// src/components/SpinningLogo.js
import React from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@emotion/react';
import logo from './logo.png'; // Replace with your logo path

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinningLogo = () => {
  return (
    <Box
      component="img"
      src={logo}
      alt="logo"
      sx={{
        animation: `${spin} infinite 5s linear`,
        height: '40vmin',
      }}
    />
  );
};

export default SpinningLogo;
