// src/App.js
import React from 'react';
import { Box, Button, Container, CssBaseline, Link, ThemeProvider, Typography, createTheme } from '@mui/material';
import SpinningLogo from './components/SpinningLogo';
import TwitterIcon from '@mui/icons-material/Twitter';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#61dafb',
    },
    background: {
      default: '#282c34',
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          textAlign: 'center',
        }}
      >
        <SpinningLogo />
        <Typography variant="h3" component="h1" gutterBottom>
          Dynamo
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          Redefining and Enhancing Yield Aggregation
        </Typography>
        <Typography variant="body1" gutterBottom>
          Dynamo aims to address the issues with traditional yield aggregators by aligning protocols, DeFi users, and the aggregator in a symbiotic relationship. Our unique flywheel mechanism and reinvestment strategy ensure sustainable growth and beneficial returns for all stakeholders.
        </Typography>
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            href="dynamo_deck_v1.pdf"
            download="dynamo_deck_v1.pdf"
            sx={{ margin: 1 }}
          >
            Download Pitch Deck
          </Button>
          <Button
            variant="contained"
            color="primary"
            href="dynamo_whitepaper_v1.pdf"
            download="dynamo_whitepaper_v1.pdf"
            sx={{ margin: 1 }}
          >
            Download Whitepaper
          </Button>
        </Box>
        <Box mt={4} textAlign="center">
          <Button
            variant="outlined"
            color="primary"
            startIcon={<TwitterIcon />}
            href="https://x.com/DynamoFinance"
            target="_blank"
            rel="noopener"
          >
            Coming Soon - 
            Follow us on X
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default App;
